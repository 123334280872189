import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import { Button } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
       <Header />
       <section className='section full p-0 m-0'>
        <div class="row h-100 d-flex flex-column">
          <div class="col-md-12 bg-home-feature h-100 bg d-flex align-items-center justify-content-left">
            <h1 style={{marginLeft: '58%'}} class="text-brown p-5">
            Welcome Your Young Family To Their New Home and ‘Extended Backyard’.
            </h1>
          </div>
        </div>
       </section>

       <section className="section full p-0 m-0">
          <div class="row h-100 d-flex flex-column">
              <div class="col-md-12 bg-home-about-image h-100 bg d-flex align-items-center justify-content-left">
                  <h1 class="text-white p-5">
                      BloomBury Is A Special Place.<br/>
                      A Place Of Energy and Vibrancy.<br/>
                      Joy and Opportunity.<br/>
                      People and Communities.<br/>
                      Family and Friends.<br/>
                      Individuality and Commonality.<br/>
                      A Place To Take Solace and A Place To Unite. <br/>
                      It Is Your Home. It Is Your Haven. It Is Your Hub… <br/>
                      <span className='text-teal'>W</span>
                      <span className='text-white'>e</span>
                      <span className='text-green'>l</span>
                      <span className='text-light-brown'>c</span>
                      <span className='text-teal'>o</span>
                      <span className='text-white'>m</span> 
                      <span className='text-green'>e</span>
                      <span className='text-light-brown'>.</span>
                    
                  </h1>
              </div>
          </div>
      </section>

      <section class="section full bg-brown p-0 m-0">
        <div class="row h-100 d-flex flex-column">
            <div class="col-md-6 d-flex flex-column justify-content-center align-content-between p-5">
                <h1 class="m-5 text-light-brown">A <span class="text-white">Home</span><br/>
                    Tailored To<br/>
                    Your Precious<br/>
                    Memories<br/>
                    (That Are Yet<br/>
                    To Happen)…</h1>
                <hr class="m-5"/>
                <p class="m-5 mt-0 text-white">
                    Your text here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. Maecenas non ipsum nunc.
                </p>
                
            </div>
            <div class="col-md-6 bg-home-home-image h-100 bg"></div>
        </div>
      </section>

      <section class="section full bg-teal p-0 m-0">
        <div class="row h-100 d-flex flex-column">
            <div class="col-md-6 bg-home-haven-image h-100"></div>
            <div class="col-md-6 d-flex flex-column justify-content-center align-content-between p-5">
                <h1 class="m-5 text-brown">
                    Welcome Your<br/>
                    Family To Their<br/>
                    New Home<br/>
                    Surrounded By A<br/>
                    <span class="text-white">Haven</span> Of Natural<br/>
                    Hotspots …</h1>
                <hr class="m-5" />
                <p class="m-5 mt-0 text-brown">
                    Your text here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. Maecenas non ipsum nunc.<br/>
                    <Button className="btn-rounded btn-brown me-2 mt-5 bg-brown text-white">
                      <span className='me-3'>Read More</span>
                      <span><svg height="15px" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                    <path d="m15.5.75v12c0,.41-.34.75-.75.75s-.75-.34-.75-.75V2.56L1.28,15.28c-.15.15-.34.22-.53.22s-.38-.07-.53-.22c-.29-.29-.29-.77,0-1.06L12.94,1.5H2.75c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h12c.1,0,.19.02.29.06.18.08.33.22.41.41.04.09.06.19.06.29Z"/>
                    </svg></span>
                    </Button>
                </p>
                
            </div>
        </div>
      </section>

      <section class="section full bg-green p-0 m-0">
          <div class="row h-100 d-flex flex-column">
              <div class="col-md-6 d-flex flex-column justify-content-center align-content-left p-5">
                  <h1 class="m-5 text-brown">
                      Live, Work   <br/>
                      And Play <br/>
                      In The Heart <br/>
                      Of Sydney's<br/>
                      Newest <span class="text-white">Hub</span>…</h1>
                  <hr class="m-5"/>
                  <p class="m-5 mt-0 text-brown">
                      Your text here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. Maecenas non ipsum nunc.
                  </p>
              </div>
              <div class="col-md-6 bg-home-hub-image h-100 bg"></div>
          </div>
      </section>
    </div>
  );
}

export default App;
